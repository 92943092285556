<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'PushMessage',
              }"
              >群發訊息</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{
              `${isEditing ? "編輯" : "新增"}訊息`
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-xl-2">
          <h4 class="font-weight-bold">
            {{ `${isEditing ? "編輯" : "新增"}訊息` }}
          </h4>
        </div>
        <div class="flex-fill d-flex justify-content-end">
          <StaffAddCustomer
            class="justify-content-end pr-3"
            ref="staffAddCustomer"
            add-button-text="測試推播"
            @bind="testBroadcast"
          ></StaffAddCustomer>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">標題</label>
        <div class="col-sm-10">
          <b-form-input
            type="text"
            class="form-control"
            placeholder="訊息標題將顯示於推播通知及聊天一覽中"
            :state="v$.title.$error ? false : null"
            maxlength="100"
            required
            v-model="title"
          />
          <b-form-invalid-feedback :state="!v$.title.$error">
            此欄位為必填
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-sm-2 col-form-label">發送時間：</label>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-6">
              <datepicker
                placeholder="日期"
                v-model="publish_at.date"
                bootstrap-styling
                format="yyyy-MM-dd"
                :language="zh"
                :input-class="v$.publish_at.date.$error ? 'is-invalid' : ''"
                :disabledDates="{ to: new Date(Date.now() - 8640000) }"
              ></datepicker>
              <b-form-invalid-feedback :state="!v$.publish_at.date.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
            <div class="col-sm-6 timepicker">
              <vue-timepicker
                :input-class="`${
                  v$.publish_at.time.HH.$error || v$.publish_at.time.mm.$error
                    ? 'is-invalid'
                    : ''
                } form-control`"
                v-model="publish_at.time"
                hide-clear-button
                :minute-interval="10"
              ></vue-timepicker>
              <b-form-invalid-feedback
                :state="
                  !v$.publish_at.time.HH.$error && !v$.publish_at.time.mm.$error
                "
              >
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-sm-2 col-form-label">受眾群組：</label>
        <div class="col-sm-10">
          <div class="row">
            <b-select class="col-4" v-model="audienceId" :options="audiences" :disabled="$route.query && $route.query.audience_id"> </b-select>
          </div>
        </div>
      </div>

      <b-card
        v-for="(i, idx) in messages"
        :key="idx"
        class="shadow-none mb-3"
        header-bg-variant="light"
        header-class="p-3"
      >
        <template #header>
          <div class="d-flex justify-content-between align-content-center">
            <b-form-radio-group
              v-model="i.content.type"
              :options="messageTypeOptions"
              @change="messageTypeChange(i, idx)"
            ></b-form-radio-group>

            <div>
              <b-button
                variant="link"
                class="text-dark"
                @click="swapMessage(idx, -1)"
                :disabled="idx == 0"
                ><i class="fa fa-angle-up" aria-hidden="true"></i
              ></b-button>
              <b-button
                variant="link"
                class="text-dark"
                :disabled="idx == messages.length - 1"
                @click="swapMessage(idx, 1)"
                ><i class="fa fa-angle-down" aria-hidden="true"></i
              ></b-button>
              <b-button
                v-if="idx > 0"
                variant="link"
                class="text-dark"
                @click="removeMessage(idx)"
                ><i class="fa fa-times" aria-hidden="true"></i
              ></b-button>
            </div>
          </div>
        </template>

        <template v-if="i.content.type == 'text'">
          <b-form-textarea
            v-model="i.content.text"
            placeholder="請輸入訊息"
            rows="6"
          ></b-form-textarea>
          <b-form-invalid-feedback> 此欄位為必填 </b-form-invalid-feedback>
        </template>

        <template v-else-if="i.content.type == 'image'">
          <div class="message-image">
            <div v-if="i.content.previewImageUrl" class="image">
              <b-img thumbnail fluid :src="i.content.previewImageUrl"></b-img>
              <b-button
                variant="danger"
                pill
                class="remove"
                @click="
                  () => {
                    i.content.previewImageUrl = null;
                    $forceUpdate();
                  }
                "
                ><i class="fa fa-times m-0" aria-hidden="true"></i
              ></b-button>
            </div>
            <button
              v-else
              @click="$bvModal.show(`modal-image-upload-${idx}`)"
              class="border p-3 text-center bg-white d-block w-100"
            >
              <div class="text-primary mb-2">上傳照片</div>
              <i
                class="fa fa-2x fa-picture-o text-secondary"
                aria-hidden="true"
              ></i>
            </button>

            <b-modal
              :id="`modal-image-upload-${idx}`"
              title="上傳照片"
              class="modal-image"
              centered
              hide-footer
            >
              <b-form-file
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                class="uploader"
                accept="image/jpeg, image/png, image/gif"
                @change="
                  uploadImage($event, i.content, `modal-image-upload-${idx}`)
                "
              >
                <template slot="placeholder">
                  <div
                    class="dropzone d-flex flex-column align-items-center justify-content-center"
                  >
                    <div>請將檔案拖放至此</div>
                    <div>或</div>
                    <div>選擇檔案</div>
                  </div>
                </template>
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template>
              </b-form-file>
            </b-modal>
          </div>
        </template>

        <template
          v-else-if="
            i.content.type == 'imagemap' && i.content.actions != undefined
          "
        >
          <div class="d-flex message-imagemap">
            <div class="image mr-4">
              <div class="template border border-primary">
                <b-img fluid :src="i.content.baseUrl"></b-img>
                <button
                  class="action border border-primary d-flex justify-content-center align-items-center"
                  v-for="(a, adx) in i.content.actions"
                  :key="adx"
                  :style="{
                    left: `${(a.area.x / i.content.baseSize.width) * 100}%`,
                    top: `${(a.area.y / i.content.baseSize.height) * 100}%`,
                    width: `${
                      (a.area.width / i.content.baseSize.width) * 100
                    }%`,
                    height: `${
                      (a.area.height / i.content.baseSize.height) * 100
                    }%`,
                  }"
                  v-b-toggle="`accordion-${adx}`"
                >
                  <span> {{ ["A", "B", "C", "D", "E", "F"][adx] }}</span>
                </button>
              </div>
              <div>
                <b-button class="mt-2" v-b-modal.modal-imagemap-template block
                  >選擇版型</b-button
                >
                <b-button
                  class="mt-2"
                  block
                  @click="$bvModal.show(`modal-imagemap-upload-${idx}`)"
                  >上傳圖片</b-button
                >
              </div>

              <b-modal
                id="modal-imagemap-template"
                title="選擇版型"
                class="modal-imagemap-template"
                centered
                hide-footer
                size="xl"
              >
                <div class="d-flex flex-wrap justify-content-center">
                  <button
                    class="btn btn-link bg-secondary template border border-primary m-2"
                    v-for="(t, tdx) in imagemapTemplate"
                    :key="tdx"
                    @click="
                      () => {
                        t.baseUrl = i.content.baseUrl;
                        messages[idx] = { content: t, order: null };
                        $bvModal.hide('modal-imagemap-template');
                        $forceUpdate();
                      }
                    "
                  >
                    <div
                      class="action border border-primary"
                      v-for="(a, adx) in t.actions"
                      :key="adx"
                      :style="{
                        left: `${(a.area.x / i.content.baseSize.width) * 100}%`,
                        top: `${(a.area.y / i.content.baseSize.height) * 100}%`,
                        width: `${
                          (a.area.width / i.content.baseSize.width) * 100
                        }%`,
                        height: `${
                          (a.area.height / i.content.baseSize.height) * 100
                        }%`,
                      }"
                    ></div>
                  </button>
                </div>
              </b-modal>

              <b-modal
                :id="`modal-imagemap-upload-${idx}`"
                title="上傳照片"
                class="modal-image"
                centered
                hide-footer
              >
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  class="uploader"
                  accept="image/jpeg, image/png, image/gif"
                  @change="
                    uploadImageMap(
                      $event,
                      i.content,
                      `modal-imagemap-upload-${idx}`
                    )
                  "
                >
                  <template slot="placeholder">
                    <div
                      class="dropzone d-flex flex-column align-items-center justify-content-center"
                    >
                      <div>請將檔案拖放至此</div>
                      <div>或</div>
                      <div>選擇檔案</div>
                    </div>
                  </template>
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                  </template>
                </b-form-file>
              </b-modal>
            </div>

            <div class="flex-fill" role="tablist">
              <b-card
                v-for="(a, adx) in i.content.actions"
                :key="adx"
                no-body
                class="mb-2 shadow-none"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="`accordion-${adx}`"
                    variant="link"
                    class="text-left"
                  >
                    {{ ["A", "B", "C", "D", "E", "F"][adx] }}
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="`accordion-${adx}`"
                  visible
                  accordion="accordion"
                >
                  <b-card-body>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label text-right"
                        >類型</label
                      >
                      <div class="col-sm-10">
                        <b-select>
                          <b-select-option>連結</b-select-option>
                        </b-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="offset-sm-2 col-sm-10">
                        <input
                          type="url"
                          class="form-control"
                          placeholder="輸入網址"
                          required
                          v-model="a.linkUri"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label text-right"
                        >動作標籤</label
                      >
                      <div class="col-sm-10">
                        <b-textarea
                          rows="6"
                          placeholder="輸入動作標籤的說明"
                          v-model="a.label"
                        >
                        </b-textarea>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </template>

        <template
          v-else-if="
            i.content.type == 'flex' && i.content.contents != undefined
          "
        >
          <div class="message-flex">
            <b-nav tabs content-class="mt-3">
              <b-nav-item
                v-for="(c, cdx) in i.content.contents.contents"
                :key="cdx"
                :active="i.content.tabIndex == cdx"
                @click="
                  () => {
                    i.content.tabIndex = cdx;
                    $forceUpdate();
                  }
                "
              >
                {{ (cdx + 1).toString() }}
              </b-nav-item>
            </b-nav>

            <div class="d-flex justify-content-end py-3">
              <b-button
                variant="outline-dark"
                class="px-2 mx-1"
                @click="flexNewPage(i)"
                ><i class="fa fa-file-o m-0" aria-hidden="true"></i
              ></b-button>
              <b-button
                variant="outline-dark"
                class="px-2 mx-1"
                @click="
                  () => {
                    if (i.content.tabIndex > 0) {
                      i.content.tabIndex--;
                      $forceUpdate();
                    }
                  }
                "
                ><i class="fa fa-angle-left mx-1" aria-hidden="true"></i
              ></b-button>
              <b-button
                variant="outline-dark"
                class="px-2 mx-1"
                @click="
                  () => {
                    if (
                      i.content.tabIndex + 1 <
                      i.content.contents.contents.length
                    ) {
                      i.content.tabIndex++;
                      $forceUpdate();
                    }
                  }
                "
                ><i class="fa fa-angle-right mx-1" aria-hidden="true"></i
              ></b-button>
              <b-button
                variant="outline-dark"
                class="px-2 mx-1"
                @click="
                  () => {
                    i.content.tabIndex--;
                    i.content.contents.contents.splice(
                      i.content.tabIndex + 1,
                      1
                    );
                    $forceUpdate();
                  }
                "
                ><i class="fa fa-times m-0" aria-hidden="true"></i
              ></b-button>
            </div>

            <div class="d-flex">
              <div class="image mr-4">
                <b-carousel
                  v-model="i.content.tabIndex"
                  controls
                  :interval="0"
                  no-wrap
                >
                  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                  <b-carousel-slide
                    v-for="(c, cdx) in i.content.contents.contents"
                    :key="cdx"
                  >
                    <template #img>
                      <b-card
                        class="m-3 flex-button rounded-lg pb-2"
                        no-body
                        :style="{
                          backgroundColor: c.styles.hero.backgroundColor,
                        }"
                      >
                        <b-badge class="altText" variant="primary">{{
                          c.hero.contents[1].contents[0].text || "宣傳標語"
                        }}</b-badge>

                        <div class="image-frame">
                          <div
                            :class="`frame-${
                              c.hero.contents
                                .map((x) => x.contents)
                                .reduce((y, z) => y.concat(z), [])
                                .filter((x) => x.type == 'image').length
                            }`"
                          >
                            <div
                              class="frame"
                              v-for="(img, imgdx) in c.hero.contents
                                .map((x) => x.contents)
                                .reduce((y, z) => y.concat(z), [])
                                .filter((x) => x.type == 'image')"
                              :key="imgdx"
                            >
                              <b-img v-if="img.url" :src="img.url" />
                            </div>
                          </div>
                        </div>

                        <div class="p-3 body">
                          <h4
                            :class="[c.body.contents[0].contents[0].size]"
                            :style="{
                              fontWeight: c.body.contents[0].contents[0].weight,
                              textAlign: c.body.contents[0].contents[0].align,
                              color: c.body.contents[0].contents[0].color,
                            }"
                          >
                            {{
                              c.body.contents[0].contents[0].text || "頁面標題"
                            }}
                          </h4>

                          <h5
                            :class="[c.body.contents[1].contents[0].size]"
                            :style="{
                              fontWeight: c.body.contents[1].contents[0].weight,
                              textAlign: c.body.contents[1].contents[0].align,
                              color: c.body.contents[1].contents[0].color,
                            }"
                          >
                            {{
                              c.body.contents[1].contents[0].text ||
                              "頁面副標題"
                            }}
                          </h5>
                          <p
                            v-for="(t, tdx) in c.body.contents[2].contents"
                            :key="tdx"
                            :class="[t.size, 'mb-0']"
                          >
                            {{ t.text || "文字說明" }}
                          </p>
                        </div>

                        <div class="footer p-0">
                          <div
                            v-for="(f, fdx) in c.footer.contents.filter((x) => {
                              return x.type == 'button';
                            })"
                            :key="fdx"
                          >
                            <b-button
                              :href="f.action.uri"
                              variant="link"
                              target="_blank"
                              block
                              class="m-0 p-2"
                              v-if="f.action.label != ''"
                              >{{ f.action.label }}</b-button
                            >
                          </div>
                        </div>
                      </b-card>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>

              <div class="flex-fill">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-right"
                    >宣傳標語</label
                  >
                  <div class="col-sm-10">
                    <b-input
                      type="text"
                      class="form-control"
                      placeholder="請輸入宣傳標語"
                      maxlength="12"
                      required
                      v-model="
                        i.content.contents.contents[i.content.tabIndex].hero
                          .contents[1].contents[0].text
                      "
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-right">圖片</label>
                  <div class="col-sm-10">
                    <b-select
                      :options="[1, 2, 3]"
                      :value="
                        i.content.contents.contents[
                          i.content.tabIndex
                        ].hero.contents
                          .map((x) => x.contents)
                          .reduce((y, z) => y.concat(z), [])
                          .filter((x) => x.type == 'image').length
                      "
                      @change="flexImageChange(i, $event)"
                    ></b-select>

                    <span
                      v-for="(img, imgdx) in i.content.contents.contents[
                        i.content.tabIndex
                      ].hero.contents
                        .map((x) => x.contents)
                        .reduce((y, z) => y.concat(z), [])
                        .filter((x) => x.type == 'image')"
                      :key="imgdx"
                    >
                      <b-button
                        v-if="img.url"
                        variant="link"
                        class="image-button mt-3 mr-3 border p-0"
                        @click="
                          $bvModal.show(
                            `modal-flex-upload-${idx}-${i.content.tabIndex}-${imgdx}`
                          )
                        "
                      >
                        <img :src="img.url" class="img-fluid" />
                      </b-button>
                      <b-button
                        v-else
                        variant="link"
                        class="image-button mt-3 mr-3 border p-0"
                        @click="
                          $bvModal.show(
                            `modal-flex-upload-${idx}-${i.content.tabIndex}-${imgdx}`
                          )
                        "
                      >
                        <span>{{ ["A", "B", "C"][imgdx] }}</span></b-button
                      >

                      <b-modal
                        :id="`modal-flex-upload-${idx}-${i.content.tabIndex}-${imgdx}`"
                        title="上傳照片"
                        class="modal-image"
                        centered
                        hide-footer
                      >
                        <b-form-file
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          class="uploader"
                          accept="image/jpeg, image/png, image/gif"
                          @change="
                            uploadFlex(
                              $event,
                              img,
                              `modal-flex-upload-${idx}-${i.content.tabIndex}-${imgdx}`
                            )
                          "
                        >
                          <template slot="placeholder">
                            <div
                              class="dropzone d-flex flex-column align-items-center justify-content-center"
                            >
                              <div>請將檔案拖放至此</div>
                              <div>或</div>
                              <div>選擇檔案</div>
                            </div>
                          </template>
                          <template slot="file-name" slot-scope="{ names }">
                            <b-badge variant="dark">{{ names[0] }}</b-badge>
                          </template>
                        </b-form-file>
                      </b-modal>
                    </span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-right"
                    >頁面標題</label
                  >
                  <div class="col-sm-10">
                    <b-input
                      type="text"
                      class="form-control"
                      placeholder="輸入標題"
                      maxlength="20"
                      required
                      v-model="
                        i.content.contents.contents[i.content.tabIndex].body
                          .contents[0].contents[0].text
                      "
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-right"
                    >頁面副標題</label
                  >
                  <div class="col-sm-10">
                    <b-input
                      type="text"
                      class="form-control"
                      placeholder="副標題"
                      maxlength="20"
                      required
                      v-model="
                        i.content.contents.contents[i.content.tabIndex].body
                          .contents[1].contents[0].text
                      "
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-right">
                    文字說明
                  </label>
                  <div class="col-sm-10">
                    <b-textarea
                      v-if="
                        i.content.contents.contents[i.content.tabIndex].body
                          .contents.length > 1
                      "
                      class="form-control"
                      rows="5"
                      placeholder="輸入文字說明"
                      maxlength="60"
                      required
                      v-model="
                        i.content.contents.contents[i.content.tabIndex].body
                          .contents[2].contents[0].text
                      "
                    />
                    <b-textarea
                      v-else
                      class="form-control"
                      rows="5"
                      placeholder="輸入文字說明"
                      disabled
                    ></b-textarea>
                  </div>
                </div>

                <div
                  v-for="(a, adx) in i.content.contents.contents[
                    i.content.tabIndex
                  ].footer.contents.filter((x) => {
                    return x.type == 'button';
                  })"
                  :key="adx"
                >
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right">
                      動作{{ adx + 1 }}
                    </label>
                    <div class="col-sm-9">
                      <b-input
                        class="form-control"
                        placeholder="輸入動作標籤的說明"
                        maxlength="15"
                        required
                        v-model.trim="a.action.label"
                      />
                    </div>
                    <div class="col-sm-1">
                      <b-button
                        variant="outline-dark"
                        size="lg"
                        class="px-3"
                        @click="
                          () => {
                            i.content.contents.contents[
                              i.content.tabIndex
                            ].footer.contents.splice(adx, 1);
                          }
                        "
                        ><i class="fa fa-times m-0" aria-hidden="true"></i
                      ></b-button>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right">
                      類型
                    </label>
                    <div class="col-sm-9">
                      <b-select>
                        <b-select-option>網址</b-select-option>
                      </b-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="offset-sm-2 col-sm-9">
                      <b-input
                        class="form-control"
                        type="url"
                        placeholder="輸入網址"
                        required
                        v-model.trim="a.action.uri"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="offset-sm-2 col-sm-10">
                    <b-button
                      variant="primary"
                      @click="
                        () => {
                          i.content.contents.contents[
                            i.content.tabIndex
                          ].footer.contents.push({
                            style: 'link',
                            type: 'button',
                            height: 'sm',
                            action: {
                              type: 'uri',
                              label: '',
                              uri: '',
                            },
                            color: '#5B82DB',
                          });
                        }
                      "
                      >新增動作</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-card>

      <div class="d-flex">
        <b-button variant="primary" @click="addMessage">新增訊息</b-button>

        <div class="flex-fill d-flex justify-content-end">
          <b-button class="mr-2" @click="save" variant="success">
            <span v-if="isEditing">儲存草稿</span>
            <span v-else>新增草稿</span>
          </b-button>
          <b-button class="mr-2" @click="saveAndSubmit" variant="success">
            <span>預約上線</span>
          </b-button>
          <b-button @click="submitNow" variant="success">
            <span>立即上線</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import pushMessageApi from "../../../apis/push-message";
import pushMessageTemplateApi from "../../../apis/push-message-template";
import audienceApi from "../../../apis/audience";
import { mapState, mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import format from "date-fns/format";
import VueTimepicker from "vue2-timepicker";
import checkPermission from "@/mixins/checkPermission";
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {PUSH_MESSAGE_STATUS} from "@/consts";
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";

const imagemapTemplate = [
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1000,
          height: 1000,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 500,
          height: 1000,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 500,
          y: 0,
          width: 500,
          height: 1000,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1000,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 500,
          width: 1000,
          height: 500,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1000,
          height: 333.333,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 333.333,
          width: 1000,
          height: 333.333,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 666,
          width: 1000,
          height: 333.333,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 500,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 500,
          y: 0,
          width: 500,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 500,
          width: 500,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 500,
          y: 500,
          width: 500,
          height: 500,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1000,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 500,
          width: 500,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 500,
          y: 500,
          width: 500,
          height: 500,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1000,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 500,
          width: 1000,
          height: 250,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 750,
          width: 1000,
          height: 250,
        },
      },
    ],
  },
  {
    type: "imagemap",
    baseUrl: "",
    altText: "",
    baseSize: {
      height: 1000,
      width: 1000,
    },
    actions: [
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 333.333,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 333.333,
          y: 0,
          width: 333.333,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 666,
          y: 0,
          width: 333.333,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 0,
          y: 500,
          width: 333.333,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 333.333,
          y: 500,
          width: 333.333,
          height: 500,
        },
      },
      {
        type: "uri",
        linkUri: "",
        label: "",
        area: {
          x: 666,
          y: 500,
          width: 333.333,
          height: 500,
        },
      },
    ],
  },
];

const textTemplate = {
  type: "text",
  text: "",
};

const imageTemplate = {
  type: "image",
  originalContentUrl: null,
  previewImageUrl: null,
};

const flexHeroTemplate = [
  {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "image",
            url: "",
            size: "full",
            aspectRatio: "4:3",
            aspectMode: "cover",
          },
        ],
      },
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: "",
            color: "#ffffff",
            align: "center",
            size: "xs",
            offsetTop: "4px",
          },
        ],
        position: "absolute",
        cornerRadius: "15px",
        backgroundColor: "#ff334b",
        height: "25px",
        offsetTop: "10px",
        offsetStart: "10px",
        paddingStart: "5px",
        paddingEnd: "5px",
      },
    ],
    paddingAll: "0px",
  },
  {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "box",
        layout: "horizontal",
        contents: [
          {
            type: "image",
            url: "",
            flex: 1,
            size: "full",
            aspectMode: "cover",
            aspectRatio: "2:3",
          },
          {
            type: "image",
            url: "",
            flex: 1,
            size: "full",
            aspectMode: "cover",
            aspectRatio: "2:3",
          },
        ],
        paddingAll: "0px",
        alignItems: "center",
      },
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: "",
            color: "#ffffff",
            align: "center",
            size: "xs",
            offsetTop: "4px",
          },
        ],
        position: "absolute",
        cornerRadius: "15px",
        backgroundColor: "#ff334b",
        height: "25px",
        offsetTop: "10px",
        offsetStart: "10px",
        paddingStart: "5px",
        paddingEnd: "5px",
      },
    ],
    paddingAll: "0px",
  },
  {
    type: "box",
    layout: "horizontal",
    contents: [
      {
        type: "box",
        layout: "horizontal",
        contents: [
          {
            type: "image",
            url: "",
            flex: 1,
            aspectMode: "cover",
            size: "full",
            aspectRatio: "2:3",
          },
        ],
        flex: 1,
        height: "100%",
        alignItems: "center",
      },
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "image",
            url: "",
            size: "full",
            flex: 1,
            aspectMode: "cover",
            aspectRatio: "4:3",
          },
          {
            type: "image",
            url: "",
            size: "full",
            flex: 1,
            aspectMode: "cover",
            aspectRatio: "4:3",
          },
        ],
        flex: 1,
      },
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: "",
            color: "#ffffff",
            align: "center",
            size: "xs",
            offsetTop: "4px",
          },
        ],
        position: "absolute",
        cornerRadius: "15px",
        backgroundColor: "#ff334b",
        height: "25px",
        offsetTop: "10px",
        offsetStart: "10px",
        paddingStart: "5px",
        paddingEnd: "5px",
      },
    ],
    paddingAll: "0px",
    alignItems: "center",
  },
];

const flexTemplate = {
  type: "bubble",
  hero: null,
  body: {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            size: "lg",
            weight: "bold",
            type: "text",
            text: "",
            align: "start",
            color: "#DE006F",
            wrap: true,
          },
        ],
      },
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: "",
            size: "md",
            weight: "bold",
          },
        ],
      },
      {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: "",
            size: "md",
          },
        ],
      },
    ],
    paddingAll: "10px",
    paddingTop: "10px",
    paddingBottom: "0px",
    paddingStart: "15px",
  },
  footer: {
    spacing: "sm",
    flex: 0,
    type: "box",
    contents: [
      {
        style: "link",
        type: "button",
        height: "sm",
        action: {
          type: "uri",
          label: "",
          uri: "",
        },
        color: "#5B82DB",
      },
      {
        style: "link",
        type: "button",
        height: "sm",
        action: {
          type: "uri",
          label: "",
          uri: "",
        },
        color: "#5B82DB",
      },
      {
        type: "spacer",
        size: "sm",
      },
    ],
    layout: "vertical",
  },
  styles: {
    hero: {
      backgroundColor: "#FFFFFF",
    },
  },
};

// const condition = (message) => {
//   if (message.content.type == "text") {
//     return { text: { required } };
//   }
// };

// let localRules = {
//   title: { required },
//   publish_at: {
//     date: { required },
//     time: { HH: { required }, mm: { required } },
//   },
// };

// let textRule = {
//   content: {
//     text: { required },
//   },
// };

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [checkPermission],
  components: { Datepicker, VueTimepicker, StaffAddCustomer },
  validations() {
    //let textRule = {};

    const localRules = {
      title: { required },
      publish_at: {
        date: { required },
        time: { HH: { required }, mm: { required } },
      },
    };

    // console.log(this.messages);

    // if (this.messages.length > 0) {
    //   textRule = {
    //     content: {
    //       text: { required },
    //     },
    //   };

    //   localRules.messages = {
    //     $each: helpers.forEach(textRule)
    //   };
    // }

    return localRules;
  },
  data() {
    return {
      title: "",
      zh,
      publish_at: {
        date: null,
        time: { HH: null, mm: null },
      },
      audiences: [],
      audienceId: null,
      status: PUSH_MESSAGE_STATUS.DRAFT,
      messageTypeOptions: [
        { text: "文字", value: "text", template: _.cloneDeep(textTemplate) },
        { text: "圖片", value: "image", template: _.cloneDeep(imageTemplate) },
        {
          text: "圖文訊息",
          value: "imagemap",
          template: _.cloneDeep(imagemapTemplate[0]),
        },
        {
          text: "圖卡按鈕",
          value: "flex",
          template: {
            type: "flex",
            tabIndex: 0,
            altText: null,
            contents: {
              type: "carousel",
              contents: [_.cloneDeep(flexTemplate)],
            },
          },
        },
      ],
      messages: [],
      imagemapTemplate: imagemapTemplate,
      flexHeroTemplate: flexHeroTemplate,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('route', ['routeQuery']),
    isEditing() {
      return this.$route.params.messageID  != "create";
    },
  },
  async mounted() {
    if (this.isEditing) this.fetchMessage();
    else if (this.$route.params.templateID) {
      this.fetchTemplateMessage();
    }
    else {
      this.messages = [{ content: _.cloneDeep(textTemplate), order: 0 }];
    }
    await this.fetchAudiences();
    if (!this.isEditing) {
      this.audienceId = this.$route.query.audience_id;
    }
  },

  methods: {
    async testBroadcast(customer) {
      const payload = {
        details: this.messages,
        customer: customer
      };
      this.$swal({
        text: `確定發送推播給 ${customer.name} 嗎？`,
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: "確定推播",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await pushMessageApi.broadcastPushMessage(
              payload
            );

            this.$swal("推播成功", "", "success");
          } catch (e) {
            console.error(e);
            this.$swal("推播失敗", "", "danger");
          }
        }
      })
    },
    async fetchAudiences () {
      let page = 1, last_page = 1

      while (page <= last_page) {
        let {data}= await audienceApi.getAudiences({per_page: 100, page})
        last_page = data.meta.last_page

        this.audiences = this.audiences.concat(data.data.map(d => ({value: d.id, text: d.title })))
        ++page
      }
    },
    async fetchTemplateMessage() {
      this.isFetch = true;

      try {
        const { data } = await pushMessageTemplateApi.getPushMessageTemplate(
          this.$route.params.templateID
        );

        this.messages = data.data.details;
        this.title = data.data.title;
        this.status = data.data.status;

        this.audienceId = data.data.audience_id;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },
    async fetchMessage() {
      this.isFetch = true;

      try {
        const { data } = await pushMessageApi.getPushMessage(
          this.$route.params.messageID
        );

        this.messages = data.data.details;
        this.title = data.data.title;
        this.status = data.data.status;

        const newDate = new Date(data.data.publish_at);
        this.publish_at.date = newDate;
        this.publish_at.time.HH = format(newDate, "HH");
        this.publish_at.time.mm = format(newDate, "mm");
        this.audienceId = data.data.audience_id;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },
    messageTypeChange: function (i, idx) {
      var template = _.cloneDeep(
        this.messageTypeOptions.find((x) => {
          return x.value == i.content.type;
        }).template
      );

      if (template.type == "flex") {
        template.contents.contents[0].hero = _.cloneDeep(flexHeroTemplate[0]);
      }

      this.messages.splice(idx, 1, { content: template, order: null });
      this.$forceUpdate();
    },

    addMessage: function () {
      this.messages.push({
        content: _.cloneDeep(textTemplate),
        order: this.messages.length,
      });
    },

    swapMessage: function (idx, order) {
      [this.messages[idx], this.messages[idx + order]] = [
        this.messages[idx + order],
        this.messages[idx],
      ];

      this.$forceUpdate();
    },
    removeMessage: function (idx) {
      this.messages.splice(idx, 1);
    },

    flexNewPage: function (i) {
      let template = _.cloneDeep(flexTemplate);
      template.hero = _.cloneDeep(flexHeroTemplate[0]);

      i.content.contents.contents.push(template);
    },
    flexImageChange: function (message, value) {
      message.content.contents.contents[message.content.tabIndex].hero =
        _.cloneDeep(flexHeroTemplate[value - 1]);
    },
    async uploadImage(e, target, modal) {
      const { data } = await this.upload(e.target.files[0]);
      target.previewImageUrl = target.originalContentUrl = data.data.image.url;
      this.$bvModal.hide(modal);
    },

    async uploadImageMap(e, target, modal) {
      const { data } = await this.upload(e.target.files[0]);
      target.baseUrl = data.data.image.url;
      this.$bvModal.hide(modal);
    },

    async uploadFlex(e, target, modal) {
      const { data } = await this.upload(e.target.files[0]);
      target.url = data.data.image.url;
      this.$bvModal.hide(modal);
    },

    async upload(file) {
      try {
        let form = new FormData();
        form.append("image", file);
        return await pushMessageApi.uploadImage(form);
      } catch (error) {
        console.log("error", error);
        this.$swal("圖片上傳失敗", "", "danger");
      }
    },

    async saveAndSubmit() {
      this.status = PUSH_MESSAGE_STATUS.RESERVE
      this.save()
    },
    async submitNow() {
      this.status = PUSH_MESSAGE_STATUS.RESERVE
      let newDate = new Date()
      this.publish_at.date = newDate
      this.publish_at.time.HH = format(newDate, "HH")
      this.publish_at.time.mm = format(newDate, "mm")
      this.save()
    },
    async save() {
      const result = await this.v$.$validate();
      if (!result) return;

      const newDate = this.publish_at.date;
      newDate.setHours(this.publish_at.time.HH);
      newDate.setMinutes(this.publish_at.time.mm);

      this.messages.forEach((m, idx) => {
        m.order = idx;
      });

      const payload = {
        title: this.title,
        status: this.status,
        publish_at: `${format(newDate, "yyyy-MM-dd")}T${format(
          newDate,
          "HH:mm:ss"
        )}`,
        details: this.messages,
        audience_id: this.audienceId,
      };

      try {
        this.isLoading = true;
        if (this.isEditing) {
          payload.id = this.$route.params.messageID;
          await pushMessageApi.updatePushMessage(
            this.$route.params.messageID,
            payload
          );

          this.$swal("修改成功", "", "success");

          this.$router.push({ name: "PushMessage", query: this.routeQuery });
        } else {
          await pushMessageApi.createPushMessage(payload);

          this.$swal("新增成功", "", "success");

          this.$router.push({ name: "PushMessage", query: this.routeQuery });
        }
      } catch (error) {
        console.error(error.response.data.message);

        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: error.response.data.message,
        });

        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data.message
        ) {
          this.validationErrors = error.response.data.message;
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.message-image {
  .image {
    max-width: 200px;
    position: relative;
    display: inline-block;

    .remove {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }
}

.uploader {
  height: 300px;
  .custom-file-input {
    height: 100%;
  }
  .custom-file-label {
    height: 100%;
    width: 100%;
    &::after {
      content: none !important;
    }

    .dropzone {
      height: 300px;
    }
  }
}

.message-imagemap {
  .image {
    width: 220px;

    .template {
      position: relative;
      border-width: 2px !important;
      min-height: 216px;

      .action {
        position: absolute;
        border-width: 2px !important;
        font-size: 18pt;
        text-align: center;
        color: white;
        font-weight: bold;
        background: rgba($color: #666, $alpha: 0.3);

        span {
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
        }
      }
    }
  }
}

#modal-imagemap-template {
  .template {
    width: 10vw;
    height: 10vw;
    border-width: 2px !important;
    position: relative;

    .action {
      position: absolute;
      border-width: 2px !important;
    }
  }
}

.message-flex {
  .image {
    width: 320px;

    .image-frame {
      height: 215px;
      position: relative;
      background-color: #ddd;

      img {
        width: 100%;
        height: 100%;
        // border: solid 1px #ddd;

        object-fit: cover;
      }

      .frame {
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
        background-color: #ddd;
        border: solid 1px white;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 18pt;
          color: white;
          font-weight: bold;
          transform: translate(-50%, -50%);
        }
      }

      .frame-1 {
        .frame {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          &::after {
            content: "A";
          }
        }
      }

      .frame-2 {
        .frame:first-child {
          position: absolute;
          top: 0;
          right: 50%;
          left: 0;
          bottom: 0;
          width: 50%;
          height: 100%;
          &::after {
            content: "A";
          }
        }

        .frame:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
          left: 50%;
          bottom: 0%;
          width: 50%;
          height: 100%;
          &::after {
            content: "B";
          }
        }
      }

      .frame-3 {
        .frame:first-child {
          position: absolute;
          top: 0;
          right: 50%;
          left: 0;
          bottom: 0;
          width: 50%;
          height: 100%;
          &::after {
            content: "A";
          }
        }

        .frame:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
          left: 50%;
          bottom: 50%;
          width: 50%;
          height: 50%;
          &::after {
            content: "B";
          }
        }
        .frame:nth-child(3) {
          position: absolute;
          top: 50%;
          right: 0;
          left: 50%;
          bottom: 0;
          width: 50%;
          height: 50%;
          &::after {
            content: "C";
          }
        }
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: 50%;
    bottom: 50%;
    background-color: #666;
    height: 35px;
    width: 35px;
    border-radius: 100%;
  }

  .flex-button {
    overflow: hidden;
    border-radius: 1rem !important;

    .altText {
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 1;
    }

    .body {
      .lg {
        font-size: 1.375rem;
      }
      .md {
        font-size: 1rem;
      }
      .sm {
        font-size: 0.875rem;
      }
    }

    .footer {
      background-color: transparent;
      border: none;
      .btn {
        font-size: 1rem;
      }
    }
  }

  .image-button {
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
}
</style>

